<template>
  <div class="aboutus-cnt1">
    <h6 class="h6">联系我们 - Contact us</h6>
    <div class="cnt">
      <p class="p">联系我们（深圳市微微数码有限公司）</p>
      <p class="p">我们期待每一个和你交流的机会，无论是投诉还是祝福，意见或建议。</p>
      <p class="p">微微定的最新动态、媒体报道、加入团队都可以通过以下方式联系我们，我们保证会在第一时间回复您。</p>
      <p class="p">服务热线：0755-88321055</p>
      <p class="p">联系邮箱：3530134266@qq.com</p>
      <p class="p">服务QQ：3530134266</p>
      <p class="p">公司地址：深圳市宝安区宝安大道塘尾地铁站旁标帆科技大厦6楼</p>
      <p class="p">服务时间： 周一至周五9:00-18:00，节假日以公告服务时间为准</p>
      <img class="codeimg" src="../../assets/codeimg.jpg">
      <!-- <img class='mapimg' src="../../assets/mapimg.jpg"> -->
      <div class="maps" id="badumapsid715"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aboutus-cnt1',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
    this.$nextTick(()=>{
      var map = new BMap.Map("badumapsid715"); // 创建Map实例
      map.centerAndZoom(new BMap.Point(113.82339,22.710741), 18); // 初始化地图,设置中心点坐标和地图级别
      //map.setMapStyleV2({
      //  styleId: '647a2d55d7567e78e80f1f4122c8616f'
      //});
      map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
      map.setCurrentCity("深圳"); // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    })
  }
}
</script>
<style  lang="scss" scoped>
.aboutus-cnt1 {
  width: 946px;float:right;background: #fff;border-radius: 5px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 16px;color:#555555;line-height: 30px;margin-bottom:10px;
    }
    .codeimg {
      margin-bottom:20px;
    }
    .maps {
      width: 912px;height:500px;border: 1px solid #6a6ffb;
    }
  }
}
</style>
